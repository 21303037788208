import { Injectable } from '@angular/core';

interface LogoEvent {
  name: string;
  fileName: string;
  startDate: Date;
  endDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  private logos: LogoEvent[] = [
    {
      name: 'Octobre rose',
      fileName: 'logo-octobre-rose.svg',
      startDate: new Date(new Date().getFullYear(), 9, 1), // 1er octobre
      endDate: new Date(new Date().getFullYear(), 9, 31)    // 31 octobre
    },
    {
      name: 'Noël',
      fileName: 'logo-noel.svg',
      startDate: new Date(new Date().getFullYear(), 11, 1), // 1er décembre
      endDate: new Date(new Date().getFullYear(), 11, 31)    // 31 décembre
    },
    {
      name: 'Oxylio',
      fileName: 'logo.svg',
      startDate: new Date(0),  // Date très ancienne pour être le fallback
      endDate: new Date(8640000000000000) // Date très lointaine
    }
  ];

  constructor() { }

  getLogoData(): { fileName: string, altText: string } {
    const today = new Date();

    for (const logo of this.logos) {
      if (today >= logo.startDate && today <= logo.endDate) {
        return { fileName: logo.fileName, altText: logo.name };
      }
    }

    const defaultLogo = this.logos.find(logo => logo.name === 'default');
    return { fileName: defaultLogo?.fileName || 'logo_default.png', altText: defaultLogo?.name || 'default' };
  }
}
