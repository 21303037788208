import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {StaticService} from '../services/static.service';
import { FilteringService } from 'src/app/search/filtering.service';
import { SearchService } from '../services/search.service';
import { Router } from '@angular/router';
import { LogoService } from '../services/logo.service';

interface MenuEntry {
  title?: string;
  link?: string;
  externeLink?: boolean;
  type?: string;
  value?: number | string[]; // Peut être un nombre, une chaîne, ou un tableau
  submenu?: MenuEntry[]; // Récurse pour permettre des sous-menus imbriqués
  marque?: string; // Si utilisé spécifiquement pour certaines logiques de marque
  range?: string; // Si utilisé pour définir des gammes de modèles
  modeles?: string[]; // Pour lister des modèles spécifiques
}

type MenuStructure = MenuEntry[];

@Component({
  selector: 'app-core-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
  
  
export class CoreMenuComponent implements OnInit {
  menuGroups = [];
  marques: string[];
  modeles: string[];
  categories: string[];
  ranges: any;
  menuPath: any[] = [];

  // for the logo 
  logoUrl: string;
  logoAlt: string;

  menuOpen = false;
  menuStructure: MenuStructure = [
        {
          title: 'Véhicules', submenu: [
            {
              title: 'Tout voir', link: '/achat/vehicules'
            },
            {
              title: 'Marques', submenu: [
                { title: '', link: '' },
              ]
            },
            {
              title: 'Modèles', submenu: [
                { range: '', modeles: [], marque: '', link: '' },
              ]
            },
            {
              title: 'Catégories', submenu: [
                { title: '', link: '' },
              ]
            },
            {
              title: "Nos rubriques", submenu: [
                { title: 'Tout voir', link: '/achat/vehicules' },
                {
                  title: "Petits prix", link: "/achat/vehicules", type: "petits_prix", value: 1
                },
                {
                  title: "Faibles kilométrages", link: "/achat/vehicules", type: "faibles_km", value: 1
                },
                {
                  title: "Occasions récentes", link: "/achat/vehicules", type: "occasions_recentes", value: 1
                },
                {
                  title: "Électriques & Hybrides", link: "/achat/vehicules", type: "carburants", value: ['Hybride', 'Electrique']
                },
                {
                  title: "Prix en baisse", link: "/achat/vehicules", type: "offers", value: ['Promotion']
                },
                {
                  title: "Prime conversion", link: "/achat/vehicules", type: "offers", value: ['Prime conversion']
                },
                {
                  title: "Offres en cours", link: "/achat/vehicules", type: "offers", value: ['Promotion', 'Prime conversion']
                },
              ]
            },

          ]
        },
        {
          title: "Reprise", link: "/reprise"
        },
        {
          title: "Financement", link: "/financement"
        },
        {
          title: "Services", submenu: [
            {
              title: "Achat en ligne", link: "/informations/services/achat-en-ligne"
            },
            {
              title: "Livraison", link: "/informations/services/livraison"
            },
            {
              title: "Reconditionnement", link: "/informations/achat/Reconditionnement"
            },
            {
              title: "Garantie", link: "/garantie-voiture-neuve-occasion"
            },
            {
              title: "Service après vente", link: "/service-apres-vente"
            },
            {
              title: "Tous nos services", link: "/informations/services"
            },
          ]
        },
        {
          title: "Agences", link: "/agences"
        },
        {
          title: "Conseils auto", link: "https://blog.oxylio.com/", externeLink: true 
        },
        {
          title: "Liens utiles", submenu: [
            {
              title: "Prime à la conversion", link: "/informations/achat/prime-a-la-conversion-2024"
            },
            {
              title: "Bonus écologique", link: "/informations/achat/bonus-ecologique-2024"
            },
            {
              title: "FAQ", link: "/informations/faq"
            },
            {
              title: "Recrutement", link: "/recrutement" ,
            },
            {
              title: "Contact", link: "/contact"
            },
          ]
        },
  ];
  

  @Output() toggleMenu = new EventEmitter<any>();

  constructor(
    private staticService: StaticService,
    private filteringService: FilteringService,
    private searchService: SearchService,
    private router: Router,
    private logoService: LogoService) { }

  async ngOnInit() {
    try {

      this.marques = (await firstValueFrom(this.searchService.search({}, 'popover'))).marques;
      this.ranges = (await firstValueFrom(this.searchService.search({ marques: this.marques }, 'popoverModele'))).modeles;
      // this.categories = (await firstValueFrom(this.searchService.search({}, 'popoverCategorie'))).categories;

      // if (this.categories) {
      //   const orderMarketing = [
      //     "Citadine",
      //     "Compacte",
      //     "Berline",
      //     "Break",
      //     "SUV",
      //     "Monospace",
      //     "Utilitaire"
      //   ];
      //   this.categories.sort((a, b) => {
      //     return orderMarketing.indexOf(a) - orderMarketing.indexOf(b);
      //   });
      // }

      this.categories = [
        "Citadine",
        "Compacte",
        "SUV",
        "Berline",
        "Break",
        "Monospace",
        "Utilitaire"
      ];

      this.updateMenuWithBrands(this.marques);
      this.updateMenuWithModels(this.ranges);
      this.updateMenuWithCategories(this.categories);

      const groups = await this.staticService.getGroups().toPromise();
      if (groups) {
        this.menuGroups = groups.filter(it => it.header);
      }

      const LogoData = this.logoService.getLogoData();
      this.logoUrl = LogoData.fileName;
      this.logoAlt = LogoData.altText
    } catch (error) { console.error(error); }
  }

  updateMenuWithBrands(brandNames: string[]) {
    const vehiculesMenu = this.menuStructure.find(menuItem => menuItem.title === 'Véhicules');

    if (vehiculesMenu && vehiculesMenu.submenu) {
      const marquesSubmenu = vehiculesMenu.submenu.find(submenuItem => submenuItem.title === 'Marques');

      if (marquesSubmenu) {
        // Mise à jour du submenu de "Marques" avec les données de l'API
        marquesSubmenu.submenu = brandNames.map(brandName => ({
          title: brandName,
          link: '/achat/vehicules',
        }));
        marquesSubmenu.submenu.unshift({ title: 'Tout voir', link: '/achat/vehicules' });
      }
    }
  }

  updateMenuWithModels(Models: [{ range: '', modeles: [], marque: '', link: '' }]) {
    const vehiculesMenu = this.menuStructure.find(menuItem => menuItem.title === 'Véhicules');

    if (vehiculesMenu && vehiculesMenu.submenu) {
      const marquesSubmenu = vehiculesMenu.submenu.find(submenuItem => submenuItem.title === 'Modèles');

      if (marquesSubmenu) {
        marquesSubmenu.submenu = Models.map(modele => ({
          range: modele.range,
          modeles: modele.modeles,
          marque: modele.marque,
          link: '/achat/vehicules',
        }));
        marquesSubmenu.submenu.unshift({ range: '', modeles: [], marque: 'Tout voir', link: '/achat/vehicules' });
      }
    }
  }

  updateMenuWithCategories(categories) {
    const vehiclesMenu = this.menuStructure.find(menu => menu.title === 'Véhicules');
    if (!vehiclesMenu) return;

    const categoriesSubMenu = vehiclesMenu.submenu.find(sub => sub.title === 'Catégories');
    if (!categoriesSubMenu) return;

    if (categoriesSubMenu) {
      categoriesSubMenu.submenu = categories.map(category => ({
        title: category,
        link: '/achat/vehicules',
      }));
      categoriesSubMenu.submenu.unshift({ title: 'Tout voir', link: '/achat/vehicules' });
    }
  }

  setFilters(filters) {
    this.filteringService.storeFilters = filters;
    this.filteringService.getVehicles();
  }
    
  setFiltersMarque(filter) {
    if (filter[0] === 'Tout voir') {
      this.setFilters({});
      return;
    }
    this.filteringService.storeFilters = { marques: filter };
    this.filteringService.getVehicles();
  }

  setFiltersRanges(filter) {
    if (filter.marque === 'Tout voir') {
      this.setFilters({});
      return;
    }
    this.filteringService.storeFilters = { marques: [filter.marque], ranges: [filter.range], modeles: filter.modeles };
    this.filteringService.getVehicles();
  }

  setFiltersCategories(filter) {
    if (filter === 'Tout voir') {
      this.setFilters({});
      return;
    }
    this.filteringService.storeFilters = { categories: [filter] };
    this.filteringService.getVehicles();
  }

  click() {
    this.toggleMenu.emit();
    if (this.menuPath[this.menuPath.length - 1]?.item.title === "Véhicules") {
      this.setFilters({})
    }
    this.menuPath = [];
  }

  openMenu(item: any, index: number) {
    this.menuPath.push({ item, index });
  }

  get currentMenu() {
    return this.menuPath.length ? this.menuPath[this.menuPath.length - 1].item.submenu : this.menuStructure;
  }

  goBack() {
    if (this.menuPath.length) {
      this.menuPath.pop();

    }
  }

  goHome() {
    this.toggleMenu.emit();
    if (this.menuPath[this.menuPath.length - 1]?.item.title === "Véhicules") {
      this.setFilters({})
    }
    this.menuPath = [];
    this.filteringService.storeFilters = { from: 0 };
    this.filteringService.setUrl({});
    this.filteringService.getVehicles();
    this.router.navigate(['/']);
  
  }

  setFilter(type: string, value: any) {
    const filterObject = {};
    filterObject[type] = value;

    this.filteringService.storeFilters = filterObject;
    this.filteringService.getVehicles();
  }
}
